import dynamic from "next/dynamic"
import Image from "components/Image"
import richText from "utilities/richText"
import cn from "classnames"
import getImageDimensions from "utilities/getImageDimensions"
import ImageHeading from "components/ImageHeading"

const SingleImage = ({ image }) => {
  return (
    <Image
      src={image?.filename}
      alt={image?.alt}
      className="rounded-4xl"
      placeholder={image?.blurDataURL ? "blur" : "empty"}
      blurDataURL={image?.blurDataURL}
      width={800}
      height={511}
    />
  )
}

const MultiImage = ({ image }) => (
  <div className="grid grid-cols-3 justify-between gap-2 lg:gap-4 overflow-hidden">
    {image.map((img, i) => (
      <Image
        key={`image-${img.id}-${i}`}
        src={img.filename}
        alt={img.alt}
        className="rounded-4xl"
        placeholder={img?.blurDataURL ? "blur" : "empty"}
        blurDataURL={img?.blurDataURL}
        style={{
          width: "100%",
          height: "auto",
        }}
        width={100}
        height={500}
      />
    ))}
  </div>
)

export default function ImageAndContent({ blok }) {
  const section = cn(
    "image-content-section relative",
    blok.background_color && `bg-${blok.background_color} lg:rounded-4xl mx-auto py-8 lg:py-0`,
  )

  function Decoration({ decoration }) {
    if (!decoration || decoration === "") return null

    let src = "",
      styles = ""

    switch (decoration) {
      case "dot-circle":
        src = "/assets/decorative-patterns/dotted-circle-blue.svg"
        styles = `-bottom-40 -right-20 lg:-bottom-12 lg:right-8 2xl:-bottom-10 size-56`
        break
      case "plus-sign":
        src = "/assets/decorative-patterns/plus-pattern-long.svg"
        styles = cn(`-left-16 -top-12 size-56 lg:size-72 lg:-left-20`, {
          "top-2 lg:-top-8 xl:-left-16": blok.background_color === "none",
        })
        break
      default:
        break
    }

    return <img src={src} alt={decoration} className={cn("absolute z-0", styles)} />
  }

  const container = cn("image-content-container flex flex-col lg:items-center gap-8 mx-auto relative", {
    "md:flex-row-reverse justify-end": blok.orientation === "content_first",
    "md:flex-row": blok.orientation === "image_first",
    "lg:py-12 2xl:py-16": blok.background_color !== "none",
  })

  const imgDecorContainer = cn("image-decor basis-1/2 relative")

  const image = "relative max-h-"

  const headingImage = blok.heading_image

  return (
    <div className="lg:pb-16 image-and-content relative">
      <div className="relative container-inset mx-auto">
        {!blok?.decoration || blok?.decoration !== "" ? <Decoration decoration={blok?.decoration} /> : null}
        <div className={section}>
          <div className="container mx-auto">
            <div className={container}>
              <div className={imgDecorContainer}>
                <div className={image}>
                  {blok.image.length < 2 ? <SingleImage image={blok.image[0]} /> : <MultiImage image={blok.image} />}
                </div>
              </div>
              <div className="basis-1/2 image-content">
                {headingImage?.filename && (
                  <ImageHeading className="justify-start" headingImage={headingImage} fallback={blok.heading} />
                )}
                <span className="eyebrow-2 text-purple-bright">{blok.eyebrow}</span>
                <div
                  className={cn("prose prose-h2:capitalize", {
                    "text-charcoal": blok?.background_color === "white",
                  })}
                >
                  {richText(blok.content)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
